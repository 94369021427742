.all_land_contact {
    font-family: 'Poppins' , sans-serif !important;
    
    position: relative;

    min-height: 300px ;

    background-color: #E30613;
    margin-top: 150px !important ;
    padding-top: 100px !important;


    .land_contact_container {
        .land_contact_row {    
            z-index: 9;
            position: relative;

            .land_contact_text1 {
                color: white;
                font-size: 40px;
                font-weight: 600;
                margin-bottom: 50px ;

            }
            .land_contact_text2 {
                color: white;
                font-size: 16px;
                font-weight: 400;
                
            }

            .land_contact_label {
                color: white;
                font-size: 16px;
                font-weight: 400;

            }

            .land_contact_btn {
                font-size: 16px ;
                font-weight: 400;
                background-color: black;
                color: white;
                padding: 0px 40px;
                height: 50px ;
                width: max-content;
                min-width: 150px;
                margin: 40px 0 ;
                cursor: pointer;
                border-radius:25px ;
                margin-bottom: 100px;
                
            }
    
        }

    }



    .land_contact_container {
        @media (min-width: 1200px) {
            max-width: 80vw ;
        }
    }
    


}
