.acount_profile_space {
    font-family: 'Poppins' , sans-serif !important;


    .image_back_card_profile {
        width: 100%;
        height: auto;
    }

    .profile_back_header:hover {
        .back_change_photo {
            display: block !important;
        }
    }


    .back_change_photo , .profile_change_photo {
        position : absolute ; width : 100% ; height : 100% ; background-color: #00000061; top : 0 ; left : 0 ;
        display: none;

        img {
            position: absolute ; top: 50%; left: 50%; transform: translate(-50% , -50%); cursor: pointer;
        }
    }
    
    
    .card_body_space {
        
        .card_profile_image {
            position: relative;
            margin-top: -20px !important;
            .card_profile_image_div {
                width: 130px !important;
                height: 130px !important;
                border-radius: 50%;
                overflow: hidden;
                padding: 3px;
                border: 4px solid #e30613;
                background-color: white;
                position: relative;
                
                img {
                    width: 100%;
                    height: auto;
                }

                
                
            }
            .card_profile_image_div:hover {
                .profile_change_photo {
                    display: block !important;
                }
            }
            .badge {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                background-color: #e30613;
                color: white;
                padding: 4px 8px;
                
            }
        }
    
        .card-profile-stats {
            .heading {
                font-size: 16px !important; color: black ;  font-weight: 500;
            }
            .description {
                font-size: 10px !important; color: #4c4e4c ;  font-weight: 500;
            }
        }
    }
}



.profile_post_option {
    color : #2f3232 !important ; font-weight: 500; font-size: 15px ; cursor: pointer;
    .profile_post_option_active_line {
        display: none;
        height: 2px;
        border-radius: 1px;
        background-color: #e30613;
        width: 80%;
        margin-left: 10%;
        margin-top: 10px;
    }
}

.profile_post_option:hover {
    
}

.profile_post_option_active {
    .profile_post_option_active_line {
        display: block;
    }
}

.information_profile_div {
    margin-top : 20px ;
    color : black ; 
    font-size : 13px ;
    div {
        margin-top: 3px !important;
    }
    .link {
        color : blue ;
    }
}

.profile_posts_container {
        display: grid ; 
        grid-template-columns : repeat(auto-fill, 300px )  ;
        grid-auto-rows: 10px ;
        justify-content: space-around ;
        @media screen and (max-width : 300px ) {
            grid-template-columns : repeat(auto-fill, 100% )  ;
            
        }
}


.text_hidden {
    margin-top: 70px;
    font-size: 3vw;
    color : black ; 
    font-weight: 600;
    text-align: center;

    .text_hidden_small {
        font-size: 1vw;
        font-weight: 500;
    }
}
.btn_more {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: default;
    transition: none;
    
    padding: 0 !important;
}
.btn_more:hover {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: default;
    transition: none;
}