.container_punchword {
    font-family: 'Poppins' , sans-serif !important;


    @media (min-width: 1200px) {
        max-width: 85vw ;
    }

    .row_punchword {
        position: relative; 
        .background_row_punchword {
            background-color: #F0F0F0 ;
            border-radius: 50px;
            position: absolute;
            width: 100%; left: 0;
            height: 70%;
            top: 15%;
            overflow: hidden;
            img {
                position: absolute;
                height: auto;
                width: 100%;
                right: 0;
                top: 0;

                @media screen and (max-width: 576px) {
                    height: 100% ; width: auto;
                }

            }
        }

        .punchytoken_text1 {
            color: black;
            font-size: 40px;
            font-weight: 600;
        }

        .punchytoken_text2 {
            color: rgb(139, 139, 139);
            font-size: 15px;
            font-weight: 400;
        }
    }

    @media screen and (max-width : 575px) {
        .image_shown_phones {
            width : 100% !important ;
            height: auto !important;
        }
        
    }

}
