.all_punchytoken {
    font-family: 'Poppins' , sans-serif !important;
    
    position: relative;

    min-height: 300px ;

    background-color: #E30613;
    // margin-top: 100px !important ;
    .background_row_punchy_token {
        z-index: 1;
        position: absolute;
        width: 90%; left: 5%;
        height: 100%; top: 0%;
        overflow: hidden;
        .line_punchytoken {
            position: absolute;
            height: 300px;
            top: -50px;
            left: 0;

        }
        .arrow_punchytoken {
            position: absolute;
            height: auto;
            right: 0;
            bottom: 0px;

        }

        @media screen and (max-width : 985px ) {
            .line_punchytoken {
                top: -70px;
            }
    
            .arrow_punchytoken {
                bottom: -70px;
    
            }
            
        }
    }
    

    .content_punchytoken {
        z-index: 3;
        background: transparent;
        .punchytoken_text {
            padding-left: 100px ;
            padding-right: 100px ;
            padding-top: 180px;
            text-align: center ;
            color: white;
            font-size: 40px;
            font-weight: 400;
    
        }
    
        .buy_punchy_btn {
            font-size: 15px ;
            background-color: black;
            color: white;
            padding: 0px 25px;
            height: 50px ;
            width: max-content;
            margin: 40px auto ;
            cursor: pointer;
            border-radius:25px ;
            margin-bottom: 100px;
        
    
        }



        @media screen and (max-width : 700px ) {
            width: 100% !important;
            .punchytoken_text {
                padding-left: 10px ;
                padding-right: 10px ;
        
            }
        }
    }


}
