.acount_profile_space {
    font-family: 'Poppins' , sans-serif !important;


    .image_back_card_profile {
        width: 100%;
        height: auto;
    }
    
    
    .card_body_space {
        
        .card_profile_image {
            position: relative;
            margin-top: -20px !important;
            .card_profile_image_div {
                width: 130px !important;
                height: 130px !important;
                border-radius: 50%;
                overflow: hidden;
                padding: 3px;
                border: 4px solid #e30613;
                background-color: white;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .badge {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                background-color: #e30613;
                color: white;
                padding: 4px 8px;
                
            }
        }
    
        .card-profile-stats {
            .heading {
                font-size: 16px !important; color: black ;  font-weight: 500;
            }
            .description {
                font-size: 10px !important; color: #4c4e4c ;  font-weight: 500;
            }
        }
    }

    .settings_settings_space {
        min-height: 100% !important;
  
        .settings_settings_space_container {
          min-height: 100%;
          padding: 10px ;
          
          position: relative;
    
        }
        
        
      }
      .text_login1 {
        font-size : 20px ;
        color: black ;
      }
      
      .text_login2 {
        position : relative ; 
        height: 60px ;
  
        hr {
          margin: 0;
          position: absolute;
          top: 50%;
          left : 0 ;
          width: 100%;
          transform: translateY(-50%);
        }
  
        span {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50% , -50%);
          font-size: 13px;
          background-color: white;
          color: gray ;
          padding-left: 20px;
          padding-right: 20px;
  
        }
      }
  
      .text_login2 {
        font-size : 12px ;
        text-align: center;
        font-weight: 300;
      }
  
      .settings_label {
        color: black;
        font-size: 13px;
        font-weight: 300;
        margin-left: 20px;
        text-align: left;
        margin-bottom: 5px;
      }
  
      .settings_forget_password {
        text-align: right;
        a {
          color: black !important;
          font-size: 16px;
          font-weight: 300;
          margin-right: 20px;
          cursor: pointer;
        }
  
        a:hover {
          color: gray !important;
  
        }
  
      }
  
    
      .settings_settings_btn {
        font-size: 15px ;
        background-color: lightgray;
        color: gray;
        min-width: 160px;
        width: 100%;
        max-width: 400px;
        height: 40px ;
        cursor: pointer;
        border-radius:20px ;
        
        font-weight: 400;
        line-height: 40px;
        margin-top: 44px;
    }
  
  
    #customCheckRegister{
      appearance: none;
  
    }
    
    
    #customCheckRegister:checked + .punchword_checkbox_checked::before {
      background-image: url('/assets/img/icons/Login/checkbox_cheked.svg') !important;
      background-size: contain;
    }
    .settings_settings_btn_valid  {
      background-color: #e30613;
      color: white;
  
    }
    .gender_input_select {
      background : #eef1ee ; 
       color : #000000 ;  appearance: none ; cursor : pointer ;
      background-image: url('/assets/img/icons/Login/select_down.svg') !important;
      background-repeat: no-repeat !important;
      background-position: right 1rem center !important;
      background-size: 1em !important;
    }
    
      @media screen and (min-width : 767px) {
        padding: 50px  ;
  
        .text_login1 {
          font-size : 35px ;
        }
  
        .text_login2 {
          font-size : 15px ;
          text-align: center;
          font-weight: 400;
        }
  
        .settings_settings_bottom {
          padding-left: 50px ; padding-right: 50px;
        }
  
  
      }
    }



.profile_post_option {
    color : #2f3232 !important ; font-weight: 500; font-size: 15px ; cursor: pointer;
    .profile_post_option_active_line {
        display: none;
        height: 2px;
        border-radius: 1px;
        background-color: #e30613;
        width: 80%;
        margin-left: 10%;
        margin-top: 10px;
    }
}

.profile_post_option:hover {
    
}

.profile_post_option_active {
    .profile_post_option_active_line {
        display: block;
    }
}

.information_profile_div {
    margin-top : 20px ;
    color : black ; 
    font-size : 13px ;
    div {
        margin-top: 3px !important;
    }
    .link {
        color : blue ;
    }
}

.profile_posts_container {
        display: grid ; 
        grid-template-columns : repeat(auto-fill, 300px )  ;
        grid-auto-rows: 10px ;
        justify-content: space-around ;
        @media screen and (max-width : 300px ) {
            grid-template-columns : repeat(auto-fill, 100% )  ;
            
        }
}


.text_hidden {
    margin-top: 70px;
    font-size: 3vw;
    color : black ; 
    font-weight: 600;
    text-align: center;

    .text_hidden_small {
        font-size: 1vw;
        font-weight: 500;
    }
}
.btn_more {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: default;
    transition: none;
}