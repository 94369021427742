.container_land_download_beta {
    font-family: 'Poppins' , sans-serif !important;
    padding-top: 100px;


    @media (min-width: 1200px) {
        max-width: 85vw ;
    }

    .row_land_download_beta {
        position: relative;

        min-height: 680px; 

        .background_row_land_download_beta {
            background-color: #F0F0F0 ;
            border-radius: 50px;
            position: absolute;
            width: 100%; left: 0;
            height: 70%;
            top: 15%;
            overflow: hidden;
            img {
                position: absolute;
                width: auto;
                height: 100% ;
                right: 0;
                top: 0;

            }
        }

        .punchytoken_text1 {
            color: black;
            font-size: 35px;
            font-weight: 600;
        }

        .punchytoken_text2 {
            color: rgb(139, 139, 139);
            font-size: 18px;
            font-weight: 400;
        }
        .phones_image_land_donwnload_beta{
            height : 1080px  ; 
            position : absolute  ; top : 0  ; right : -140px  ; z-index : 3 ; 
        }

        @media (min-width: 767px) and  (max-width: 1210px) {
            .phones_image_land_donwnload_beta{
                left: 20px !important;
            }
        }

        @media (min-width: 575px) and (max-width: 767px) {
            
            .phones_image_land_donwnload_beta{
                height : 720px  ; 
                left: 200px !important;
            }
        }

        @media screen and (max-width: 575px)  {
            
            .phones_image_land_donwnload_beta{
                height : 720px  ; 
                left: 20px !important;
            }
        }


        @media screen and  (max-width: 767px) {
            min-height: 580px; 

            margin-bottom:  350px;

            .download_image_store_div {
                margin-top: 100px;
                margin-bottom: 100px ;;
                .download_image_store {
                    width: 130px !important;
                }
            }

            
        }
    }

}
