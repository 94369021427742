.navbar-top {
    position: fixed !important;
    background-color: white !important;
    z-index: 10 !important;
    .navbar-link {
        color: black !important ;
    }
}

.navbar-dark .navbar-nav .nav-link {
    color : black !important ;
    font-weight: 300 !important;
    font-size: 14px !important;
    transition: none;
    a {
        color : black !important ;
        font-weight: 300 !important;
        transition: none;
        cursor: pointer;
    }
    @media screen and (max-width: 991px) {
        font-size: 10px !important;
    }
}

.navbar-dark .navbar-nav .nav-link:hover {
    color : rgba($color: #000000, $alpha: 0.6) !important ;
    font-weight: 200 !important;
    a {
        color : rgba($color: #000000, $alpha: 0.6) !important ;
        font-weight: 200 !important;
    }
}


.login_btn {
    font-size: 12px ;
    background-color: #e30613;
    color: white;
    padding: 0px 25px;
    height: 36px ;
    cursor: pointer;
    border-radius:18px ;
    font-weight: 400 !important;

    @media screen and (max-width: 991px) {
        font-size: 10px !important;
        height: 30px !important ;
    }
}

.navbar-toggler-icon {
    background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.95%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.nav_bar_container {
    @media (min-width: 1200px) {
        max-width: 80vw ;
    }
}