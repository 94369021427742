.stories_list_home_space {
    font-family: 'Poppins' , sans-serif !important;
    margin-top: 2vh;


    .image_back_card_profile {
        width: 100%;
        height: auto;
    }
    
    
    .one_storie_list_home {
        max-width: 100px;
        .card_profile_image {
            position: relative;
            .card_profile_image_div {
                
                width: 64px !important;
                height: 64px !important;
                border-radius: 50%;
                overflow: hidden;
                padding: 4px;
                border: 2px solid #e30613;
                
                img {
                    width: 100%;
                    height: auto;
                    cursor: pointer;
                }
            }
            .badge {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                background-color: #e30613;
                color: white;
                padding: 4px 8px;
                
            }
        }
    
        .card-profile-stats {
            .heading {
                font-size: 11px !important; color: #2f3232 ;  font-weight: 500;
            }
            .description {
                font-size: 9px !important; color: #4c4e4c ;  font-weight: 500;
            }
        }
    }

    .storie_username {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-wrap: break-word;
        font-size : 10px ;
        color : #2f3232 ;
        margin-bottom: 0; 
        height: 15px ;
        overflow: hidden;

    }
}

.scroll-horizontal div:first-child {
    position: relative !important;
}