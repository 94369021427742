.all_market_item_space {
    font-family: 'Poppins' , sans-serif !important;
    .video_container {
        position: relative;
        .video_timing_space {
            position: absolute;
            z-index: 999;
            width: 150px ;
            height: 30px;
            left: 0;
            bottom: 0;
            
            background-color: #00000085;
            color: white !important;
            border-top-right-radius: 10px;
        }
    }
}

.post_image_container {
    background-size: cover !important;
    background-position: center !important;
}