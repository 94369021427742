.all_land_footer {
    font-family: 'Poppins' , sans-serif !important;
    
    position: relative;

    min-height: 200px ;

    background-color: black;


    .land_footer_container {
        .land_footer_row {

            .land_footer_text1 {
                color: white;
                font-size: 40px;
                font-weight: 600;
                margin-bottom: 50px ;

            }
            .land_footer_text2 {
                color: white;
                font-size: 18px;
                font-weight: 400;
                
            }

            .land_footer_label {
                color: white;
                font-size: 16px;
                font-weight: 400;

            }

            .land_footer_btn {
                font-size: 16px ;
                font-weight: 400;
                background-color: black;
                color: white;
                padding: 0px 40px;
                height: 50px ;
                width: max-content;
                min-width: 150px;
                margin: 40px 0 ;
                cursor: pointer;
                border-radius:25px ;
                margin-bottom: 100px;
                
            }
    
        }

    }



    .land_footer_container {
        @media (min-width: 1200px) {
            max-width: 80vw ;
        }


        @media (min-width: 767px) and  (max-width: 1200px) {
            .logo_footer_image {
                height: 70px !important;
            }
            .icon_footer_image {
                height: 30px !important;
            }
            .land_footer_text2 {
                font-size: 14px !important;

            }
            
        }

        @media screen and (max-width: 767px) {
            .logo_footer_image {
                height: 50px !important;
                margin-bottom: 20px !important;
                margin-top: 20px !important;
            }
            .icon_footer_image {
                height: 30px !important;
                margin-bottom: 20px !important;
            }
            .land_footer_text2 {
                font-size: 12px !important;

            }
            
        }
    }
    


}



.bottom_footer_div {
    background-color: #101010;
    color  : white !important;
    padding: 20px ; 




    @media (min-width: 767px) and  (max-width: 1200px) {
        
        .land_footer_text2 {
            font-size: 14px !important;

        }
        
    }

    @media screen and (max-width: 767px) {
        
        .land_footer_text2 {
            font-size: 12px !important;

        }
        
    }
    
}
