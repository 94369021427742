.post_option {
    color : #2f3232 !important ; font-weight: 500; font-size: 18px ; cursor: pointer;
    .post_option_active_line {
        display: none;
        height: 3px;
        border-radius: 1px;
        background-color: #e30613;
        width: 80%;
    }
}

.post_option:hover {
    font-weight: bold;
}

.post_option_active {
    font-weight: bold;
    .post_option_active_line {
        display: block;
    }
}


.cat_item_in_list{
    width : max-content ; height : 40px ; font-size : 15px ; cursor : pointer ;
     border-radius : 20px ; 
     color: rgb(161, 161, 160);
     background: #fffffd;
     margin-left: 15px !important;
}

.cat_item_in_list.active_item , .cat_item_in_list:hover {
    color : white !important ; background : #e30613 !important ;
}

.cat_title {    
    color: black;
    font-size: 20px;
    font-weight: 600;
}

.cat_title2 {
    font-size: 12px;
    color: gray;
    margin-left: 20px;
}

.filter_btn {    
    position: sticky;
    bottom: 5px;
    right: 5px;
    width: 70px;
    height: 70px;
    /* float: right; */
    margin-left: calc( 100% - 100px );
    margin-bottom: 50px; 
    margin-top: 50px ;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .filter_btn_div {
        width: 50px;
        height: 50px;
        position: relative;
        top: 0;
        left: 0 ;

        img {
            position : absolute ; 
            left: 50%;
            top: 50%;
            transform: translate(-50% , -50%);
        }
    }
}