.acount_details_space {
    font-family: 'Poppins' , sans-serif !important;


    .image_back_card_profile {
        width: 100%;
        height: auto;
    }
    
    
    .card_body_space {
        margin-top: -42px !important;
        .card_profile_image {
            position: relative;
            .card_profile_image_div {
                
                width: 84px !important;
                height: 84px !important;
                border-radius: 50%;
                overflow: hidden;
                padding: 4px;
                border: 2px solid #e30613;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .badge {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                background-color: #e30613;
                color: white;
                padding: 4px 8px;
                
            }
        }
    
        .card-profile-stats {
            .heading {
                font-size: 11px !important; color: #2f3232 ;  font-weight: 500;
            }
            .description {
                font-size: 9px !important; color: #4c4e4c ;  font-weight: 500;
            }
        }
    }
}

