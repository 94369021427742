.container_amazing_features {
    font-family: 'Poppins' , sans-serif !important;
    padding-top: 100px;


    @media (min-width: 1200px) {
        max-width: 85vw ;
    }

    .row_amazing_features {
        position: relative;
        min-height: 680px;
        .background_row_amazing_features {
            background-color: #F0F0F0 ;
            border-radius: 50px;
            position: absolute;
            width: 100%; left: 0;
            height: 70%;
            top: 15%;
            overflow: hidden;
            img {
                position: absolute;
                height: 100%;
                width: auto ;
                right: 0;
                top: 0;

            }
        }

        .land_amazing_features_item {
            border-radius: 15px; background-color: white;
            padding: 20px;
            max-width: 250px !important;
            border-radius: 5px;
            padding-bottom: 50px;
            border : 1px solid #D0D0D0 ;
            .rounded-circle {
                width: 80px; height: 80px; font-size: 50px ; font-weight: 600;  text-align: center; color : black ; 
            }
        }
    }

}



.punchytoken_text1 {
    color: black;
    font-size: 35px;
    font-weight: 600;
}

.punchytoken_text2 {
    color: rgb(139, 139, 139);
    font-size: 18px;
    font-weight: 400;
}



.punchytoken_text3 {
    margin-top: 20px;
    color: black;
    font-size: 18px;
    font-weight: 400;
}



.punchytoken_text4 {
    margin-top: 20px;
    color: black;
    font-size: 11px;
    font-weight: 400;
}
