.container_land_download {
    font-family: 'Poppins' , sans-serif !important;
    padding-top: 100px;


    @media (min-width: 1200px) {
        max-width: 85vw ;
    }

    .row_land_download {
        position: relative;
        min-height: 505px;
        .background_row_land_download {
            background-color: #F0F0F0 ;
            border-radius: 50px;
            position: absolute;
            width: 100%; left: 0;
            height: 60%;
            top: 20%;
            overflow: hidden;
            img {
                position: absolute;
                height: 100%;
                width: auto ;
                right: 0;
                top: 0;

            }
        }

        .land_contact_btn {
            font-size: 16px ;
            font-weight: 400;
            background-color: black;
            color: white;
            padding: 0px 40px;
            height: 50px ;
            width: max-content;
            min-width: 150px;
            margin: 40px 0 ;
            cursor: pointer;
            border-radius:25px ;
            
        }

        .punchytoken_text1 {
            color: black;
            font-size: 35px;
            font-weight: 600;
        }

        .punchytoken_text2 {
            color: rgb(139, 139, 139);
            font-size: 18px;
            font-weight: 400;
        }

        @media screen and  (max-width: 767px) {
            .download_image_store_div {
                margin-top: 100px !important;
                margin-bottom: 100px !important;
                .download_image_store {
                    width: 130px !important;
                }
            }

            .background_row_land_download {
                top : auto ; 
                bottom: 10% !important;
            }
            
            
        }


        @media screen and (max-width : 575px) {
            .image_shown_phones {
                width : 100% !important ;
                height: auto !important;
            }
            
        }
    }

}
