.all_messages_space {
    font-family: 'Poppins' , sans-serif !important;
    width: 100%;
    background: rgb(255, 255, 253);
    position: relative;
    top: 20px;
    /* transform: translateY(-50%); */
    height: 80vh;
    z-index: 5;

    .messages_space_card {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;
        .messages_space_row {
            position: relative;
            height: 100%;
            width: 100%;
            padding: 0;
        }
        .messages_list_space {
            position: relative;
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.05);

            .messages_list_space_search {
                height: 50px;
                width: 100%;
            }

            .messages_list_space_items {
                height: calc(100% - 70px);
                overflow: hidden;
                width: 100%;

                
            }
        }

        .messages_discution_space {
            position: relative;
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.05);

            .messages_discution_space_user {
                height: 50px;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            }
            
            .messages_discution_list_space_items {
                height: calc(100% - 150px);
                overflow: hidden;
                width: 100%;
                font-size: 12px;
                .message_user {
                    width : 100% ;
                    .message_sender {
                        width: max-content;
                        border-radius: 20px;
                        background-color: #e4e4e4;
                        color: black;
                        margin-top: 20px;
                    }
                    .message_reciver {
                        width: max-content;
                        border-radius: 20px;
                        background-color: #fd7f88;
                        color: white;
                        margin-top: 20px;
                        
                    }
                }

                
            }
            .messages_discution_space_message {
                height: 50px;
                width: 100%;
                position: relative;

                .input-group-alternative {
                    padding-right: 40px !important;
                }

                .messages_discution_space_message_tools {
                    width: 40px !important;
                    position: absolute;
                    right: 15px;
                    top: 0 ;
                    height: 100%;
                    z-index: 9999;
                    .messages_tools {
                        height: 100% ;
                        width: 100%;
                    }
                }
            }
        }
    }

}

.item_image_hover {
    display: none ;
}

.actionPost:hover {
    cursor: pointer;
    background-color: #fffffd !important;
    /* border-radius: 5px;*/
    .item_image {
        display: none;
    }
    .item_image_hover {
        display: block;
    }
    
    .text_action strong {
        color: #e30613 !important;

    }
}


    
.card_profile_image {
    position: relative;
    .card_profile_image_div {
        
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        overflow: hidden;
        padding: 2px;
        border: 2px solid #e30613;
        
        img {
            width: 100%;
            height: auto;
        }
    }
    .badge {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 8px;
        background-color: #e30613;
        color: white;
        padding: 4px 8px;
        
    }
}
.image_notification_like_all {
    position: relative;
    height: 40px;
    width: 40px;
    .image_notification_like_div1 {
        position: absolute;
        top: 0%;
        left: 0%;
        // transform: translate(10px , 10px);
        width: 30px !important;
        height: 30px !important;
        border-radius: 50%;
        overflow: hidden;

        border: 2px solid white;
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            width: 100%;
            height: auto;
        }
    }
    .image_notification_like_div2 {
        position: absolute;
        bottom:  0%;
        right: 0%;
        // transform: translate(-10px , -10px);
        width: 30px !important;
        height: 30px !important;
        border-radius: 50%;
        overflow: hidden;

        border: 2px solid white;
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            width: 100%;
            height: auto;
        }
    }
}

.message_liste_item_content {
    font-size: 11px;
    color: #7D7D7D;
    height: 20px;
    overflow: hidden;
    text-align: left;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: break-word;
    

}

.message_not_readed {
    color : black !important ;
}


.ps__rail-y {
    left: auto ;
    right: 0 !important;
}
