.storie_background {
    width: 100%;
    height: 100%;
    background-color: black;
    width: 250px;
    height: 450px;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover !important;
    background-position: center !important;
    overflow: hidden;
    transition: 200ms;
    cursor: pointer;
    .stories_content {
        display: none;
    }
    .one_storie_list_home {
        .card_profile_image {
            position: relative;
            .card_profile_image_div {
                
                width: 100px !important;
                height: 100px !important;
                border-radius: 50%;
                overflow: hidden;
                padding: 4px;
                border: 3px solid #e30613;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .badge {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                background-color: #e30613;
                color: white;
                padding: 4px 8px;
                
            }
        }
    
        .card-profile-stats {
            .heading {
                font-size: 11px !important; color: #2f3232 ;  font-weight: 500;
            }
            .description {
                font-size: 9px !important; color: #4c4e4c ;  font-weight: 500;
            }
        }
    }
    
    .storie_username {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-wrap: break-word;
        font-size : 15px ;
        color : white ;
        margin-bottom: 0;
        font-weight: 600; 
        overflow: hidden;
    
    }
}

.active_story {
    height: 800px;
    width: 450px;
    margin-left: 70px !important;
    margin-right: 70px !important;
    cursor: default;
    .stories_content {
        display: flex;
    }
    .one_storie_list_home {
        display: none !important;
    }
}