.body_register_space {
  min-height: 100vh; min-width: 100vw ;
  overflow: hidden;
  font-family: 'Poppins' , sans-serif !important;
  background-color: #eef1ee;
  z-index: 100;
  .all_register_space {
    min-height: 600px ; 
    max-width: 900px;
    border-radius: 50px !important;
    overflow: hidden;
    padding: 0px;
    background-color: white ;
    
  

    .row_register_space {
      min-height: 600px;
    }
  
    .register_register_space {
      min-height: 100% !important;

      .register_register_space_container {
        min-height: 100%;
        padding: 10px ;
        
        position: relative;
  
        .text_login1 {
          font-size : 20px ;
          color: black ;
        }
        
        .text_login2 {
          position : relative ; 
          height: 60px ;

          hr {
            margin: 0;
            position: absolute;
            top: 50%;
            left : 0 ;
            width: 100%;
            transform: translateY(-50%);
          }

          span {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            font-size: 16px ;
            background-color: white;
            color: gray ;
            padding-left: 20px;
            padding-right: 20px;

          }
        }
  
        .text_login2 {
          font-size : 12px ;
          text-align: center;
          font-weight: 300;
        }

        .register_label {
          color: black;
          font-size: 13px;
          font-weight: 300;
          margin-left: 20px;
        }

        .register_forget_password {
          text-align: right;
          a {
            color: black !important;
            font-size: 16px;
            font-weight: 300;
            margin-right: 20px;
            cursor: pointer;
          }

          a:hover {
            color: gray !important;

          }

        }
  
      
        .register_register_btn {
          font-size: 15px ;
          background-color: lightgray;
          color: gray;
          min-width: 160px;
          width: 100%;
          max-width: 400px;
          height: 40px ;
          margin: 20px auto ;
          cursor: pointer;
          border-radius:20px ;
          
          font-weight: 400;
          line-height: 40px;
          margin-top: 44px;
      }


      #customCheckRegister{
        appearance: none;

      }
      
      
      #customCheckRegister:checked + .punchword_checkbox_checked::before {
        background-image: url('/assets/img/icons/Login/checkbox_cheked.svg') !important;
        background-size: contain;
      }
      .register_register_btn_valid  {
        background-color: #e30613;
        color: white;

      }
      .gender_input_select {
        background-image: url('/assets/img/icons/Login/select_down.svg') !important;
        background-repeat: no-repeat !important;
        background-position: right 1rem center !important;
        background-size: 1em !important;
      }
      
        @media screen and (min-width : 767px) {
          padding: 50px  ;
  
          .text_login1 {
            font-size : 35px ;
          }
  
          .text_login2 {
            font-size : 15px ;
            text-align: center;
            font-weight: 400;
          }
  
          .register_register_bottom {
            padding-left: 50px ; padding-right: 50px;
          }
  
  
        }
      }


    }
  

    @media screen and (max-width : 575px) {
      border-radius: 0px !important;
    }
    
  }
}