.all_sidenav {
    font-family: 'Poppins' , sans-serif !important;
    width: 350px;
    background: rgb(255, 255, 253);
    position: fixed;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    height: 80vh;
    top: 20px;
    z-index: 5;
}

.item_image_hover {
    display: none ;
}

.actionPost:hover {
    cursor: pointer;
    background-color: #fffffd !important;
    /* border-radius: 5px;*/
    .item_image {
        display: none;
    }
    .item_image_hover {
        display: block;
    }
    
    .text_action strong {
        color: #e30613 !important;

    }
}