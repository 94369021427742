.container_land_create_punchword {
    font-family: 'Poppins' , sans-serif !important;
    


    @media (min-width: 1200px) {
        max-width: 85vw ;
    }

    .row_land_create_punchword {
        position: relative;
        

        .background_row_land_create_punchword {
            background-color: #F0F0F0 ;
            border-radius: 50px;
            position: absolute;
            width: 100%; left: 0;
            height: 70%;
            top: 15%;
            overflow: hidden;
            img {
                position: absolute;
                height: 100%;
                width: auto ;
                left: 0;
                top: 0;

            }
        }

        .punchytoken_text1 {
            color: black;
            font-size: 35px;
            font-weight: 600;
        }

        .punchytoken_text2 {
            color: rgb(139, 139, 139);
            font-size: 18px;
            font-weight: 400;
        }



        .punchytoken_text3 {
            margin-top: 20px;
            color: black;
            font-size: 18px;
            font-weight: 400;
        }



        .punchytoken_text4 {
            margin-top: 20px;
            color: black;
            font-size: 11px;
            font-weight: 400;
        }
        .phones_image_land_create_punchword{
            height : 200px   ; 
            width: auto ;
            position : absolute  ; top : -100px  ; right : 0px  ; 
            @media (min-width : 767px) and (max-width : 1210px) {
                top : -50px  ;
            }

            @media screen and (max-width : 767px) {
                top : 0
            }
        }

        .land_create_punchword_item {
            border-radius: 15px; background-color: #F0F0F0;
            padding: 20px;
            max-width: 250px !important;
            .rounded-circle {
                width: 80px; height: 80px; font-size: 50px ; font-weight: 600;  text-align: center; color : black ; 
            }
        }

        .row_content_image_create_punchword {
            margin-top: 50px;
            @media (min-width : 767px) and (max-width : 1210px) {
                margin-top: 100px !important;
            }

            @media screen and (max-width : 767px) {
                margin-top: 200px !important;
            }
        }
        
    }

}
