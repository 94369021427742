.container_land_coming_soon {
    font-family: 'Poppins' , sans-serif !important;
    padding-top: 100px;


    @media (min-width: 1200px) {
        max-width: 85vw ;
    }

    .row_land_coming_soon {
        position: relative;

        min-height: 680px; 

        .background_row_land_coming_soon {
            background-color: #F0F0F0 ;
            border-radius: 50px;
            position: absolute;
            width: 100%; left: 0;
            height: 70%;
            top: 15%;
            overflow: hidden;
            img {
                position: absolute;
                height: 100%;
                width: auto ;
                left: 0;
                top: 0;

            }
        }

        .punchytoken_text1 {
            color: black;
            font-size: 35px;
            font-weight: 600;
        }

        .punchytoken_text2 {
            color: rgb(139, 139, 139);
            font-size: 18px;
            font-weight: 400;
        }
        .phones_image_land_coming_soon{
            height : 200px   ; 
            width: auto ;
            position : absolute  ; top : -50px  ; left : 0px  ; z-index : 3 ; 
        }

        @media screen and  (max-width: 767px) {
            min-height: 680px; 
            margin-bottom:  500px;
            
        }
    }

}
