.acount_profile_space {
    font-family: 'Poppins' , sans-serif !important;


    .image_back_card_profile {
        width: 100%;
        height: auto;
    }
    
    
    .card_body_space {
        
        .card_profile_image {
            position: relative;
            margin-top: -20px !important;
            .card_profile_image_div {
                width: 130px !important;
                height: 130px !important;
                border-radius: 50%;
                overflow: hidden;
                padding: 3px;
                border: 4px solid #e30613;
                background-color: white;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .badge {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                background-color: #e30613;
                color: white;
                padding: 4px 8px;
                
            }
        }
    
        .card-profile-stats {
            .heading {
                font-size: 16px !important; color: black ;  font-weight: 500;
            }
            .description {
                font-size: 10px !important; color: #4c4e4c ;  font-weight: 500;
            }
        }
    }



.show-more-less-clickable , a {

    color : blue !important ;
}



.profile_post_option {
    color : #2f3232 !important ; font-weight: 500; font-size: 15px ; cursor: pointer;
    .profile_post_option_active_line {
        display: none;
        height: 2px;
        border-radius: 1px;
        background-color: #e30613;
        width: 80%;
        margin-left: 10%;
        margin-top: 10px;
    }
}

.profile_post_option:hover {
    
}

.profile_post_option_active {
    .profile_post_option_active_line {
        display: block;
    }
}

.profile_post_text {
    color : black ; 
    font-size : 13px ;
    text-align: left;
    border-radius: 16px ;
    padding: 10px;
    min-height: 100px;
    height: 100%; 
    overflow: hidden;
    padding-top: 30px;
    div {
        margin-top: 3px !important;
    }
    .post_link {
        color : blue !important ;
    }
}
}