.all_blocklist_bar {
    font-family: 'Poppins' , sans-serif !important;
    position: fixed;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    height: 100vh;
    width: 100%;
    top: 0px;
    left: 0;
    z-index: 10000000;
    background-color: #000000b0;
    display: none ;
    .all_blocklist_card {
        max-height: 90vh;
        box-shadow: 0 0 2rem 0 #0000006e !important ;
        width: 350px;
        background: rgb(255, 255, 253);
        overflow: hidden;

        .card_profile_image {
            position: relative;
            .card_profile_image_div {
                
                width: 50px !important;
                height: 50px !important;
                border-radius: 50%;
                overflow: hidden;
                padding: 2px;
                border: 2px solid #e30613;
                
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .badge {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8px;
                background-color: #e30613;
                color: white;
                padding: 4px 8px;
                
            }
        }
        .image_notification_like_all {
            position: relative;
            height: 50px;
            width: 50px;
            .image_notification_like_div1 {
                position: absolute;
                top: 0%;
                left: 0%;
                // transform: translate(10px , 10px);
                width: 40px !important;
                height: 40px !important;
                border-radius: 50%;
                overflow: hidden;
    
                border: 2px solid white;
                
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                    width: 100%;
                    height: auto;
                }
            }
            .image_notification_like_div2 {
                position: absolute;
                bottom:  0%;
                right: 0%;
                // transform: translate(-10px , -10px);
                width: 40px !important;
                height: 40px !important;
                border-radius: 50%;
                overflow: hidden;
    
                border: 2px solid white;
                
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                    width: 100%;
                    height: auto;
                }
            }
        }
    
        .follow_btn {
            font-size: 12px ;
            background-color: #e30613;
            color: white;
            padding: 0px 10px;
            height: 36px ;
            cursor: pointer;
            border-radius:18px ;
        }
    
        .unfollow_btn {
            font-size: 12px ;
            color: #e30613;
            background: white;
            border: 1px solid #e30613 ;
            padding: 0px 10px;
            height: 36px ;
            cursor: pointer;
            border-radius:18px ;
        }
    }
    

}

.item_image_hover {
    display: none ;
}

.image_notification_like {
            
    width: 50px !important;
    height: 50px !important;
    border-radius: 5px;
    overflow: hidden;
    
    img {
        width: auto ;
        height: 100%;
    }

}

.ps__rail-y {
    left: auto ;
    right: 0 !important;
}

.actionPost:hover {
    cursor: pointer;
    background-color: #fffffd !important;
    /* border-radius: 5px;*/
    .item_image {
        display: none;
    }
    .item_image_hover {
        display: block;
    }
    
    .text_action strong {
        color: #e30613 !important;

    }
}